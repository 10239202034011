<template>
  <div>
    <v-row class="mt-5" justify="center">
      <p>Reset password confirmation</p>
    </v-row>
    <v-form ref="form" class="my-5 mx-3 font-weight-light">
      <v-text-field
        label="New Password"
        name="password"
        prepend-icon="mdi-lock"
        v-model="password"
        :append-icon="togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="togglePassword = !togglePassword"
        :type="togglePassword ? 'text' : 'password'"
        :rules="rules.requireCheck.concat(rules.passwordCheck)" 
      />
      <v-text-field
        label="Confirm New Password"
        name="confirmPassword"
        prepend-icon="mdi-lock-check"
        v-model="confirmPassword"
        @keyup.enter="onConfirm"
        :append-icon="toggleConfirm ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="toggleConfirm = !toggleConfirm"
        :type="toggleConfirm ? 'text' : 'password'"
        :rules="rules.requireCheck.concat(rules.passwordRule)"
      />
    </v-form>
    <v-row class="px-5 my-5">
      <v-btn
        dark
        class="font-weight-medium propercase"
        color="primary"
        @click="onConfirm"
        block
      >
        Submit
      </v-btn>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "ResetConfirmPage",
  data() {
    return {
      password: null,
      confirmPassword: null,
      togglePassword: false,
      toggleConfirm: false,
      rules: {
        requireCheck: [(v) => !!v || "Input is required"],
        passwordCheck: [
          (v) =>
            /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*(){}<>?]).{8,}/.test(v) ||
            "Password must contain uppercase and lowercase letters, a number and a special character.",
        ],
        passwordRule: [
          (v) =>
            v === this.password || "Password does not match.",
        ],
      },
    };
  },
  methods: {
    gotoLink(link) {
      this.$router.push({ name: link });
    },
    async onConfirm() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const actionPayload = {
        new_password1: this.password,
        new_password2: this.confirmPassword,
        uid: this.$route.params.uuid,
        token: this.$route.params.token
      }

      try {
        const response = await this.$store.dispatch("auth/resetConfirm", actionPayload);
        this.$store.commit(
          "ui/SNACK_BAR",
          response
        );
        this.$router.replace("/auth");
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to reset password, try again later."
        );
      }
    },
  },
};
</script>
<style scoped></style>
